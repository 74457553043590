import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Brand from "../components/Brand"
import Container from "../components/Container"
import { BareLayout as Layout } from "../components/Layout"
import NewsletterForm from "../components/NewsletterForm"
import SEO from "../components/SEO"

const Description = styled.div`
  margin: ${({ theme: { spacing } }) =>
    `${spacing.lg} ${spacing.sm} ${spacing.xl}`};
  font-size: ${({ theme: { spacing } }) => spacing.lg};
  text-align: left;
  color: ${({ theme: { colors } }) => colors.accent};
`

const Info = styled.div`
  margin: ${({ theme: { spacing } }) => `${spacing.lg} 0 ${spacing.xl}`};
  font-size: ${({ theme: { spacing } }) => spacing.sm};
  text-align: ${({ align }) => align || "center"};
`

const NewsletterPage = () => (
  <Layout>
    <SEO title="The Fumble Ducks" />
    <Brand full />

    <Container as="main">
      <Description>
        <p>
          highly <u>subjective</u> and <u>opinionated</u> but{" "}
          <u>entertaining</u> content. one label, four fumble ducks and some
          guests.
        </p>
      </Description>
      <NewsletterForm />

      <Info>
        Read a <Link to="/about/">short mission statement</Link> explaining what
        the fumble ducks blog is about.
      </Info>
    </Container>
  </Layout>
)

export default NewsletterPage
